<template>
  <div class="btn">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buttonClicked: false,
    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.btn {
}
.btn:active {
  transform: scale(1.08);
}
</style>
