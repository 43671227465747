import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import Button from 'vant/lib/button';
import Popup from 'vant';
import 'vant/lib/index.css';

import '@/assets/font.css'

import '@/assets/btn.css'

import Btn from '@/components/Btn.vue'
Vue.component('Btn', Btn)
/* import Return from '@/components/Return.vue'
Vue.component('Return', Return) */

Vue.use(Popup);
Vue.use(Button)
Vue.prototype.axios = axios
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
