<template>
  <div class="body">
    <div>
      <div>
        <router-link to="/train/index">
            <van-button type="info" size="large">家长训练营</van-button>
        </router-link>
      </div>
      <div>
        <router-link to="/exam/exam_introduce">
          <van-button type="info" size="large">家长测评量表</van-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showPopup() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 2vw;
  height: 100vh;
  > div {
    width: 100%;
    > div:first-child {
      margin-bottom: 5vw;
    }
  }
}
</style>
